/*******************************************************************************

  Project: ChemBud
  Author: XXX
  Date: XX.XXXX

********************************************************************************

  1. Scroll to top
  2. Off canvas menu
  3. EU Cookie
  4. Dropdown Menu
  5. Lightbox for images and videos
  6. Responsive Videos
  7. Scoll Animations Init
  8. Object Fit Images
  9. Framework Scripts
  10. Contact Form 7 Fix
  11. Accordion

*******************************************************************************/

jQuery( document ).ready(function($) {

/* 1. Scroll to top
------------------------------------------------------------------------------*/

  $(document).on( 'scroll', function(){
    if ($(window).scrollTop() > 200) {
      $('.scroll-to-top').addClass('show');
    } else {
      $('.scroll-to-top').removeClass('show');
    }
  });

  $('.scroll-to-top').on('click', function(event) {
    event.preventDefault();
    var verticalOffset = typeof(verticalOffset) != 'undefined' ? verticalOffset : 0;
    var element = $('body');
    var offset = element.offset();
    var offsetTop = offset.top;
    $('html, body').animate({scrollTop: offsetTop}, 500, 'linear');
  });

/* 2. Off canvas menu
------------------------------------------------------------------------------*/

  $('.toggle-nav').click(function() {
    // Calling a function in case you want to expand upon this.
    toggleNav();
  });

  function toggleNav() {
    if ($('#site-wrapper').hasClass('show-nav')) {
      // Do things on Nav Close
      $('#site-wrapper').removeClass('show-nav');
    } else {
      // Do things on Nav Open
      $('#site-wrapper').addClass('show-nav');
    }
  }

  // Fuction for hiding mobile navigation
  function hideNav() {
    if ($('#site-wrapper').hasClass('show-nav')) {
      $('#site-wrapper').removeClass('show-nav');
    }
  }

  // Hide navigation when user pressed Escape key on keyboard
  $(document).on('keyup', function(e){
    if (e.keyCode === 27) {
      if ($('#site-wrapper').hasClass('show-nav')) {
        toggleNav();
      }
    }
  });

  // Hide mobile navigation when user increases browser size
  enquire.register('screen and (min-width: 768px)', {
    match : function() {
      hideNav();
    }
  });

  $('ul.mob-menu li').has('ul').addClass('parentLi');
  $('ul.mob-menu li ul').addClass('sub-menu');

  $('ul.mob-menu .sub-menu').hide();

  $('ul.mob-menu .parentLi > a').on('click', function(event){
    event.preventDefault();
    $(this).toggleClass('expanded');
    $(this).parent().find('ul.sub-menu').slideToggle();
  });

/* 4. EU Cookie
------------------------------------------------------------------------------*/

  // Variables
  var expires_date = new Date('December 31, 2099 23:59:59');
  var cookie_notice_height = $('.cookie-bar').height();

  // Update variables and padding after screen resize
  $(window).on("debouncedresize", function( event ) {
    cookie_notice_height = $('.cookie-bar').height();
    if(!(Cookies.get('eu-cookie-law-chembud') == 'accepted' || Cookies.get('eu-cookie-law-chembud') == 'refused')) {
      $('.site-footer .wrap').css('padding-bottom', cookie_notice_height + 'px');
    }
  });

  // Show cookie notice if it's not configured
  if(Cookies.get('eu-cookie-law-chembud') == 'accepted' || Cookies.get('eu-cookie-law-chembud') == 'refused') {
    $('body').removeClass('cookie-bar--not-decided');
  }
  else {
    $('.site-footer .wrap').css('padding-bottom', cookie_notice_height + 'px');
    $('body').addClass('cookie-bar--not-decided');
  }

  // Hide cookie if it's accepted
  $('.js-accept-cookie').on('click', function(e){
    e.preventDefault();
    Cookies.set('eu-cookie-law-chembud', 'accepted', { expires: expires_date });
    $('body').removeClass('cookie-bar--not-decided');
  });

  // Disable scripts and hide notice
  $('.js-refuse-cookie').on('click', function(e){
    e.preventDefault();
    if(theme_settings.cookie_popup == 1) {
      $.magnificPopup.open({
        items: {
            src: '<div class="white-popup">' + theme_settings.cookie_popup_text + '</div>',
            type: 'inline'
        },
        mainClass: 'mfp-with-fade',
        removalDelay: 160
      });
    }
    else {
      console.log('Tracking scripts and cookies are disabled.');
    }
    Cookies.set('eu-cookie-law-chembud', 'refused', { expires: expires_date });
    // Opt out functions
    // doOptout();
    $('body').removeClass('cookie-bar--not-decided');
  });

/* 5. Dropdown Menu
------------------------------------------------------------------------------*/

  $('.primary-menu .menu-item-has-children > a').on('click', function(e){
    e.preventDefault();
    $(this).parent().toggleClass('opened');
  });

  // Hide dropdown after click outside the menu
  $(document).on('click', function(event) {
    if (!$(event.target).closest('.primary-menu .menu-item-has-children a').length) {
      $('.primary-menu .menu-item-has-children').removeClass('opened');
    }
  });

/* 6. Lightbox for images and videos
------------------------------------------------------------------------------*/

  $('a[href$=jpg], a[href$=jpeg], a[href$=jpe], a[href$=png], a[href$=gif]').magnificPopup({
    type: 'image',
    removalDelay: 500,
    mainClass: 'mfp-with-fade',
    closeOnContentClick: true,
    midClick: true
  });

  $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-with-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    iframe: {
			patterns: {
        youtube: {
          index: 'youtube.com/',
          id: function(url) {
                  var m = url.match(/[\\?\\&]v=([^\\?\\&]+)/);
                  if ( !m || !m[1] ) return null;
                  return m[1];
              },
          src: '//www.youtube.com/embed/%id%?autoplay=1'
        },
				youtube_short: {
				  index: 'youtu.be/',
				  id: 'youtu.be/',
				  src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
				}
			}
		}
  });

/* 7. Responsive Videos
/*******************************************************************************/

  fitvids({
    // players: 'iframe[src*="example.com"]'
  });

/* 8. Scoll Animations Init
/*******************************************************************************/

  AOS.init({
    disable: 'mobile'
  });

/* 9. Object Fit Images
/*******************************************************************************/

  objectFitImages();

/* 10. Framework Scripts
/*******************************************************************************/

//= include framework-scripts.js

/* 11. Contact Form 7 Fix
/*******************************************************************************/

  $('.wpcf7-form p:empty').remove();

/* 12. Accordion
/*******************************************************************************/

  // Hide all accordions
  var allPanels = $('.accordion dd').hide();
  var allTitles = $('.accordion dt');

  $('.accordion dt > a').click(function(e) {
  e.preventDefault();
  $this = $(this);
  $title = $this.parent();
  $target =  $this.parent().next();

  if(!$target.hasClass('accordion__text--active')){
    allPanels.removeClass('accordion__text--active').slideUp();
    allTitles.removeClass('accordion__subtitle--active');
    $target.addClass('accordion__text--active').slideDown();
    $title.addClass('accordion__subtitle--active');
  }
  else {
    allPanels.removeClass('accordion__text--active').slideUp();
    allTitles.removeClass('accordion__subtitle--active');
  }
  return false;
  });

/* 13. matchHeight
/*******************************************************************************/

  $('.offer-grid__text').matchHeight();
  $('.site-footer__center, .site-footer__right').matchHeight();
  $('.map-form__form, .acf-map').matchHeight();

  // $( ".wpcf7-submit" ).click(function() {
  //   setTimeout(
  //       function() {
  //           $.fn.matchHeight._update('.map-form__form, .acf-map')
  //       },
  //       2500);
  // });

  // var wpcf7Elm = document.querySelector( '.wpcf7' );
  //
  // wpcf7Elm.addEventListener( 'wpcf7submit', function( event ) {
  //     setTimeout(
  //         function() {
  //             $.fn.matchHeight._update('.map-form__form, .acf-map')
  //         },
  //         100);
  // }, false );

/* 14. Slick Carousel
/*******************************************************************************/

  $('.testimonials__row').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
        }
      },
    ]
  });


  $('.logo-slider__row').slick({
    dots: false,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 5,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: true,
        }
      },
    ]
  });



    $('.gallery__big').slick({
     slidesToShow: 1,
     slidesToScroll: 1,
     arrows: false,
     fade: true,
     asNavFor: '.gallery__nav',
     adaptiveHeight: true,
   });
   $('.gallery__nav').slick({
     slidesToShow: 4,
     slidesToScroll: 1,
     asNavFor: '.gallery__big',
     dots: false,
     centerMode: true,
     focusOnSelect: true,
     responsive: [
       {
         breakpoint: 768,
         settings: {
           slidesToShow: 2,
         }
       },
     ]
   });


/*  Map
/*******************************************************************************/

   $.each($('.acf-map'), function(){
     var lat = $(this).data('lat');
     var long = $(this).data('long');

     L.Icon.Default.imagePath = theme_settings.template_dir + '/assets/img/leaflet/';

     var mymap = new L.map($(this)[0]).setView([lat, long], 16);
     var marker = L.marker([lat, long]).addTo(mymap);

     // create custom icon
    var pinIcon = L.icon({
        iconUrl: theme_settings.template_dir + '/assets/img/leaflet/pin.svg',
        shadowUrl:  theme_settings.template_dir + '/assets/img/leaflet/marker-shadow.png',
        iconSize: [40, 50], // size of the icon
        iconAnchor:   [16, 50],
        shadowAnchor: [10, 39],
        popupAnchor:  [5, -48]
    });

     L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
     attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
     maxZoom: 18,
     id: 'mapbox.streets',
     accessToken: 'pk.eyJ1IjoicHJpbWFsaW5lIiwiYSI6ImNqaTBpcHo2ZjE4aHIzcW50eWhkc3pvZHQifQ.9QH5xnelSWpPx5k30J3ljQ'
     }).addTo(mymap);
   });

});
